@charset "utf-8";
/* CSS Document */

/* SENIOR NAVIGATOR CUSTOM CSS */
/*
*
CONTENTS
*
2. RESET
3. TYPOGRAPHY
4. GLOBAL
5. COLORS
6. NAVBAR
7. BUTTONS
8. ICONS
9. FORM CONTROL
10. SEARCH FORMS
11.HERO
12.HEADER
13.BREADCRUMBS
14.CARDS
15.SERVICE LIST
16.PAGINATION
17.PROGRAM / SERVICE DETAILS PAGE
18.PROGRAM PAGE PRINT
19.211 Chat
20.CTA
21.FOOTER
22.MEDIA QUERIES
23.CALCULATOR
24.REGISTRATION FORM
25. LANDING PAGE
26. FONTS
*
*/


/* RESET */
html, body {
    padding: 0;
    margin: 0;
    font-family: 'Roboto';
    color: rgba(0,0,0,1.0);
    /*Reset base font size*/
    font-size: 18px !important;
    height: 100%;
}
a:focus, .btn:focus, input:focus, .focus a, select:focus {
    outline: none !important;
}
.btn:focus, .btn.focus {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,0)
}
a {
    color: #448AFF;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: #448AFF;
}
a:hover {
    text-decoration: none;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999999;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999999;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #999999;
}
::-ms-clear {
  display: none;
}
/* TYPOGRAPHY */
h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
}
h1 {
    font-family: 'PT Serif', serif;
    font-size: 2rem;
    line-height: 2.1rem;
}
h2 {
    font-family: 'PT Serif', serif;
    font-size: 1.944rem;
    line-height: 2rem;
}
h3 {
    font-size: 1.333rem;
    line-height: 1.667rem;
}
h4 {
}
h5 {
}
h6 {
    font-size: 1rem;
}
p {
    font-size: 1rem;
}

/* HOW/HIDE RESPONSIVE */
.md-hide {
    display: none;
}
.lg-show {
    display: none;
}
.lg-hide{
     display: list-item;
}
.xs-hide {
    display: none;
}
/* COLORS */
.purple {
    color: #311B92;
}
.blue {
    color: #448AFF;
}
.green {
    color: #00C853;
}
.red {
    color: #C51162;
}
.orange {
    color: #FF6D00;
}
.yellow {
    color: #FFAB00;
}
.grey{
    color: #999999;
}

/* NAVBAR */
/* IE 10+ FIX */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
.navbar-toggler {
    position: absolute !important;
    margin-top: -15px !important;
    margin-left: -30px !important;
}
}

.navbar-homeicon{
  font-size: 20px !important;
}

.navbar {
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.12);
    padding: 0.5rem 0rem;
    min-height: 70px;
}
/* Fix the menu scrolling issue */
nav.navbar.fixed-top {
  max-height: 100vh;
  overflow-y: auto;
}
.bg-light {
    background-color: #ffffff !important;
}
.navbar-light .navbar-brand {
  width: 24px;
  height: 24px;
  position: relative;
  margin-top: -20px;
}
.navbar-light a {
  text-decoration: none;
}
.navbar-light span {
  padding-left: 5px;
  font-size: 1rem;
}
.navbar-light .navbar-toggler {
    color: rgba(0,0,0,1);
    font-weight: 700;
    font-size: 1rem;
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(0,0,0,1);
    font-size: 17px;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}
.navbar-light .navbar-nav .nav-link:hover {
    font-weight: 700;
}
.navbar-light .navbar-text {
    color: rgba(0,0,0,1);
    font-weight: 700;
}
.navbar-nav .active {
    color: #448AFF;
}
.navbar-navigation {
    padding-top: 1rem;
    padding-bottom: 2rem;
    display: block;
}
.navbar-navigation a {
    display: block;
    text-decoration: none;
    padding: 0.4rem 1rem;
    color: #000000;
    border: 1px solid #eaeaea;
    margin-bottom: 5px;
    -webkit-box-shadow: 0 3px 30px -6px rgba(0,0,0,0.12);
    -moz-box-shadow: 0 3px 30px -6px rgba(0,0,0,0.12);
    box-shadow: 0 3px 30px -6px rgba(0,0,0,0.12);
}
.navbar-navigation a i{
  font-size: 1.15rem;
}
.navbar-navigation a:hover {
    text-decoration: none;
    -webkit-box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
    -moz-box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
    box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
    text-decoration: underline;
}
.lang-lg-hide-fr{
  text-align: right;
}
.lang-lg-hide-en{
  text-align: right;
}
.btn-lang-xs-show{
  display: none;
}

.navbar-navigation a span {
  text-decoration: none;
  margin-right: 10px;
}
.navbar-navigation h3{
  margin-bottom: 1rem;
}
.navbar-search {
    padding-top: 1rem;
    margin-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: block;
}
.navbar-search form input, .jumbotron form input, .search-results form input {
    width: 100%
}
.navbar-search .btn-search, .search-results .btn-search {
    width: 20%;
    margin-left: -20%;
}
.jumbotron .btn-search, .search-results .btn-search {
    width: 25%;
    margin-left: -25%;
}
.navbar-search-btn {
    -webkit-border-radius: 0rem 1.5rem 1.5rem 0rem !important;
    -moz-border-radius: 0rem 1.5rem 1.5rem 0rem !important;
    border-radius: 0rem 1.5rem 1.5rem 0rem !important;
    background-color: #f0f0f0;
    border-color: #f0f0f0;
    color: #448AFF;
}
.navbar-justify-end{
}

.header-container {
  /* Prevent navbar from covering page content */
  margin-bottom: 30px;
}

.navbar-default {
  background-color: white;
}
.navbar-default .no-boxshadow {
  box-shadow: none;
}
.navbar-default .search-form {
  border: none;
}
.navbar-default .navbar-search {
  border: none;
  padding-top: 0;
}
.navbar-default .collapse-menu a {
  font-weight: lighter;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-default .btn-menu {
  background: none;
  border: none;
}
.navbar-default .btn-menu:hover {
  background: none;
  border: none;
}
.navbar-default .nav-item .dropdown {
  width: 111.74px !important;
}
.navbar-default .navbar-brand {
  margin-right: 0;
  margin-right: 10px;
}
.navbar-default .navbar-brand .navbar-brand-text {
  padding: 0px;
  margin: 0px;
  margin-right: 50px;
}
.navbar-default .navbar-brand span {
  display: inline-block;
  font-family: 'PoppinsMedium';
  font-size: 17px;
  white-space: nowrap;
  color: black;
  width: 150px;
  padding-right:100px;
  margin-right: 50px;
  line-height: 1rem;
  margin-left: 0.5rem;
}
.navbar-default .navbar-brand span:hover {
  color: black;
}
.nav-item {
  margin-top: 6px;
  width: 100px;
}
.nav-link:hover {
  background-color: #a6dae2;
  color: white;
}
.dropdown-toggle:after {
  content: none;
}
.nav-item .dropdown-menu {
  margin-top: 0;
}
.navbar-default .services-tab {
  height: fit-content;
  width: fit-content;
  top: 56px;
  border-radius: 0;
  padding: 0;
  border: none;
  -webkit-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
}
.navbar-default .services-tab ul {
  text-decoration: none;
  padding-left: 0;
  list-style: none;
}
.navbar-default .services-tab .list-group.list-group-flush {
  max-height: 80vh;
  overflow: auto;
}
.navbar-default .services-tab .list-group-item {
  color: #4e4e4e;
  text-decoration: none;
  font-weight: bold;
}
.navbar-default .services-tab a {
  color: #4e4e4e;
  text-decoration: none;
}
.navbar-default .services-tab a:hover {
  color: #33bcd2;
}
.navbar-phonebar {
  background-color: white;
  white-space: nowrap;
}
.navbar-phonebar .form-label {
  color: #848484;
  font-weight: bolder;
}

/* BUTTONS */
.btn {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    -webkit-border-radius: 1.5rem;
    -moz-border-radius: 1.5rem;
    border-radius: 1.5rem;
    transition: 0.3s;
    font-size: 0.95rem;
    border-width: 3px;
    text-decoration: none;
}
.btn-icon {
    padding-left: 1rem;
}
.btn-navbar {
    background-color: none;
    border: none;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    color: #000000;
}
.btn-navbar i {
    color: #448AFF;
}
.btn-navbar:hover, .btn-hover .active {
    color: #448AFF;
}
.btn-menu{
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  -webkit-border-radius: 1.5rem;
  -moz-border-radius: 1.5rem;
  border-radius: 1.5rem;
  line-height: 1.5;
  transition: 0.3s;
  font-size: 0.95rem;
  padding: .375rem .25rem .375rem .25rem;
  text-align: center;
  background-color: #ffffff;
  border-color: #ffffff;
  color: #000000;
  cursor: pointer;
  transition: 0.3s;
}
.btn-menu i, .btn-close i{
  position: relative;
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
  line-height: 0;
}
.btn-search i{
  position: relative;
  font-size: 1.25rem;
  padding: 0;
  margin: 0;
  line-height: 0;
  color: #000;
}
.btn-menu:hover, .btn-menu:focus {
    outline : none;
  }
  .btn-en-fr{
    font-weight: bold;
    position: relative;
    top: -3px;
    margin-right: 2px;
  }
.btn-close {
    float: right;
}
.application-button{
    background-color: #79242F !important;
    border-color: #79242F !important;
    color: #ffffff !important;
    font-size: 15px !important;
    border-radius: 10px !important;
    font-family: 'MontserratBold' !important;
    -webkit-box-shadow: 0 8px 20px -6px rgba(0,0,0,0.16);
    -moz-box-shadow: 0 8px 20px -6px rgba(0,0,0,0.16);
    box-shadow: 0 8px 20px -6px rgba(0,0,0,0.16);
}
.application-button:hover{
    background-color: #000080 !important;
    border-color: #000080 !important;
    -webkit-box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
    -moz-box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
    box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
}
/* Button primary */
.btn-primary {
    background-color: #448AFF;
    border-color: #448AFF;
    color: #ffffff !important;
    -webkit-box-shadow: 0 8px 20px -6px rgba(0,0,0,0.16);
    -moz-box-shadow: 0 8px 20px -6px rgba(0,0,0,0.16);
    box-shadow: 0 8px 20px -6px rgba(0,0,0,0.16);
}
.btn-primary:hover {
    -webkit-box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
    -moz-box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
    box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
}
.btn-secondary {
    background-color: #79242F;
    border-color: #79242F;
    font-size: 15px;
    font-family: 'MontserratBold';
    border-radius: 5px;
    -webkit-box-shadow: 0 8px 20px -6px rgba(0,0,0,0.16);
    -moz-box-shadow: 0 8px 20px -6px rgba(0,0,0,0.16);
    box-shadow: 0 8px 20px -6px rgba(0,0,0,0.16);
}
.btn-secondary:hover {
    background-color: #000080;
    border-color: #000080;
    -webkit-box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
    -moz-box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
    box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
}
.btn-loader{
  max-width: 500px;
}
/* FORM CONTROL */

.form-control, .form-control-lg {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color:#fff;
    border-color: #000;
    -webkit-border-radius: 1.5rem;
    -moz-border-radius: 1.5rem;
    border-radius: 1.5rem;
    font-size: 1rem;
}
.form-control {
    line-height: 1.5rem;
    /* reg form fields forcing height to align with buttons */
    height: 46.5px;
}
/* SEARCH FORMS */
.search-form-wrapper{
  display: block;
  text-align: center;
  margin: 0 auto;
  position: relative;
}
.search-form-input{
  height: 50px;
  display: inline-block;
  -webkit-border-radius: 0rem;
  -moz-border-radius: 0rem;
  border-radius: 0rem;
  border: 0px solid #ffffff;
  font-size: 1.333rem;
  font-weight: 700;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: rgba(255,255,255,1);
  line-height: 50px;
}
.search-form{
  border-bottom: 3px solid #000000;
  margin-top: 0rem;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}
.search-form-pill {
  background-color: #ededed;
  height: 60px;
  margin-top: 0rem;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}
.search-form-pill .btn-search-icon-pill {
  background-color: #ff9864;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 70px;
  top: 0px;
  right: 0px;
  display: block;
  height: 60px;
  width: 70px;
  text-align: center;
  border: none;
  cursor: pointer;
  color: white;
  position: absolute;
}
.search-form-pill .btn-search-icon-pill:hover {
  background-color: #ef7e4b;
}
.search-form-pill .btn-search-icon i {
  color: white;
  font-size: 1.25rem;
  position: relative;
  padding: 0;
  margin: 0;
}
.search-form-pill .search-form-input {
  height: fit-content;
  width: 100%;
  float: left;
  -webkit-border-radius: 0rem;
  -moz-border-radius: 0rem;
  border-radius: 0rem;
  border: 0px solid #ededed;
  font-family: 'PoppinsMedium', Arial, Helvetica, sans-serif;
  font-weight: 100;
  -o-text-overflow: clip;
  text-overflow: clip;
  background: #ededed;
}
.jumbotron .search-form{
  max-width: 900px;
}
.search-form label{
  display: none;
  margin-left: 15px;
}
.search-form h3{
  height: 40px;
  margin: 0;
  line-height: 40px;
}
.btn-search-icon{
  display:block;
  height: 60px;
  width: 50px;
  text-align: center;
  color: #448AFF;
  position: absolute;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
}
_:-ms-fullscreen, :root
.btn-search-icon {
margin-top: -25px;
}
.btn-search-icon i{
  position: relative;
  font-size: 1.75rem;
  padding: 0;
  margin: 0;
  line-height: 55px;
}
option {
    display: block;
    min-height: 1.2em;
    padding: 0.5rem 1rem !important;
}
/* CUSTOM SELECT FORM */
.custom-select {
    display: inline-block;
    vertical-align: middle;
    background-color: #ffffff;
    background: url("../images/caret-down.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
    background-size: 1.3rem;
    cursor: pointer;
    -webkit-border-radius: 0rem;
    -moz-border-radius: 0rem;
    border-radius: 0rem;
    height: 60px;
    border: 0px solid #ffffff;
    border-bottom: 3px solid #000000;
    margin-top: 0rem;
    margin-bottom: 1rem;
    padding-bottom: 2px;
    font-size: 1.333rem;
    font-weight: 700;
    padding-right: 60px;
    -o-text-overflow: clip;
    text-overflow: clip;
    line-height: 40px;
}
.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
.form-text {
    color: #000000;
}
/* Header links */
.headerLinksContainer {
  margin-top: 70px;
  /*margin-top: 5px;*/
  font-size: 1.5rem;
  line-height: 1.5rem;
  background-color: #f2f2f2;
  padding: 7px;
  width: 100%;
  display: flex;
  flex-direction: 'row';
}

.headerLinksRight {
    width: 50%;
    display: flex;
    flex-direction: 'row';
}

.bigLink {
  flex: 0.5;
}

/* BETA NOTICE */
.betaContainer {
  margin-top: 70px;
  font-size: 1rem;
  line-height: 1rem;
  background-color: #f2f2f2;
  padding: 7px;
  width: 100%;
  }
.beta {
  float: left;
}
.betaButton {
  background-color: #00C853;
  color: #fff;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px;
  margin-top: 7px;
  border-radius: 5px;
}
.betaButton a {
  color: #fff !important;
}

.containerRed{
  background-color: indianred !important;
}

.containerRed a{
  color:lightblue !important;
}

/* HERO */
.jumbotron {
    background-color: #ffffff;
    padding: 2.5rem 0rem 2rem 0rem;
    margin-bottom: 0;
}
.jumbotron h1 {
    max-width: 50rem;
    text-align: center;
    margin: 0 auto;
}
.jumbotron p {
    padding: 20px 0px 30px 0px;
    max-width: 45rem;
    text-align: center;
    margin: 0 auto;
}
.buckets{
}
/* HEADER */
.header {
    background-color: #ffffff;
    padding: 0rem 0rem 1.5rem 0rem;
    border-bottom: 2px solid #f1f3f4;
}
.header-blank {
    background-color: #ffffff;
    padding: 0rem 0rem 0rem 0rem;
}
.header-blank h1 {
    font-size: 1.66rem;
    line-height: 1.88rem;
}
.header h1 {
    font-size: 1.66rem;
    line-height: 1.88rem;
}
.header-select {
    margin-bottom: 1rem;
}
.header-select h3 {
    margin-bottom: 0.5rem;
}
.header-select-menu-wrapper {
    padding: 0rem 1rem 1rem 1rem;
    border-bottom: 2px solid #f1f3f4;
    margin: 0rem auto 2rem auto;
}
.header-select-menu-wrapper label {
    display: block;
    text-align: center;
    margin-right: 0.25rem;
}
.header-select-menu-wrapper select {
  color: #999999;
}

.btn.btn-navbar.lg-show.ml-lg-4.inactiveLink {
  pointer-events: none;
  cursor: default;
}
.primary-header{
  width: 100%;
  font-size: 12px;
  text-align: center;
  font-family: 'MontserratMedium';
}
.primary-header a{
  text-decoration: none;
}
.primary-header a:hover{
  text-decoration: underline;
}
.primary-header button:hover{
  text-decoration: underline;
}
.primary-header-container{
  height: 35px;
  width: 100%;
  background-color: #083686;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.primary-header-container row{
  display: block;
}
.primary-header-container .container{
  padding-left: 0px;
  padding-right: 0px;
}
.primary-header-container .col{
  max-width: fit-content;
}
.primary-header a{
  color: white;
  font-weight: normal;
}
.header-language{
  position: relative;
  bottom: 6px;
}
.header-language .btn{
  font-size: 12px;
}
/* BREADCRUMBS */
.breadcrumbs {
    background-color: #ffffff;
    padding: 0.5rem 0rem;
}
.breadcrumbs p {
    font-size: 0.77rem;
}
.breadcrumbs p a {
    font-weight: 400;
}
.breadcrumbs a {
  font-size: 0.77rem;
}
.breadcrumbs a {
  font-weight: 400;
}

/* CARDS */

.card {
    border-style: none;
    margin-bottom: 2.778rem;
    position: relative;
}
.service-list-item .card-img-top {
  height: 200px;
}
.card-img-top {
  flex-shrink: 0;  /*Fixes the responsive image height issue in IE */
  object-fit: cover;
  height: 220px;
}
.card-icon-inline {
  font-size: 35px;
  vertical-align: middle;
  color: #ff9864;
}
.card-icon {
    display: block;
    border-radius: 100px;
    background-color: #ffffff;
    position: relative;
    left: 72%;
    margin-top: -40px;
    height: 80px;
    width: 80px;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.12);
    clear: both;
}

.card-icon i {
    font-size: 2.5rem;
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.card-body {
    padding: 0rem 1.75rem 2.25rem 1.75rem;
}
.card-desc-h6 {
  letter-spacing: 0.1em;
}
.card .btn {
    display: inline-flex;
    float: left;
    width: 100%;
}
.card a{
   flex-shrink: 0; /*Fixes the responsive image height issue in IE */
}
.card a img{
    opacity: 1;
   transition: opacity .25s ease-in-out;
   -moz-transition: opacity .25s ease-in-out;
   -webkit-transition: opacity .25s ease-in-out;
}

.card a img:hover{
  opacity: 0.7;
}
.telecare {
  -webkit-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.12);
  -moz-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.12);
  box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.12);
}
.telecare .card-body {
    padding-top: 2rem;
}
/* SERVICE LIST */

.service-list-item {
    padding-bottom: 2rem;
    min-height: 260px;
}
.service-list-image{
  flex-shrink: 0;
}
.service-list-description {
    padding: 2rem 1.75rem 2.25rem 1.75rem;
}
.service-list-description h3 {
    margin-bottom: 0.5rem;
}
.service-list-description h3 a, .card h3 a {
    color: #000;
    text-decoration: underline;
}
.service-list-description h3 a:hover, .card h3 a:hover {
    text-decoration: none;
}
.related-service-item {
  margin-bottom: 0px;
}
/* Service List Item Loader */
.infinite > div { /*Div the component wraps around the loader */
    width:100% !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.infinite{ /*Fix the bootstrap padding issue */
  margin-left: -15px;
  margin-right: -15px;
}
.service-list-loader{  /*Add padding to the loader */
  display: block;
  padding-top: 2rem;
  padding-bottom: 8rem;
}
.list-loader-padding{  /*Add padding to the loader */
  padding-bottom: 6rem;
}
.spinner-border{ /*Style the loading animation */
  position: absolute;
  left: 50%;
  margin-left: -35px;
  right: 50%;
  width: 70px;
  height: 70px;
  border-width: 10px;
}

/* PAGINATION */
.pagination-wrapper {
    padding: 1rem 0 5rem 0;
    text-align: center;
}
.pagination .page-item.active .page-link {
    background-color: #448AFF;
    border-color: #448AFF;
}
.page-item.disabled .page-link, .pagination .page-link {
    border-color: #f1f3f4;
    border-width: 2px;
}
.pagination .page-link:hover {
    background-color: #448AFF;
    border-color: #448AFF;
    color: #fff;
}
/* GENERIC PAGE */

.page-details {
    margin-bottom: 5rem;
}
.page-details .title {
    margin-bottom: 1.5rem;
}
.page-details .contents {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.page-details .content {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.page-details .content ol li {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/* SEARCH RESULTS */

.search-results-item {
    margin-bottom: 2rem;
}
.search-results-item-txt {
    margin-bottom: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
}
.search-results-description {
    padding: 1rem 1.75rem 2.25rem 1.75rem;
}
.search-results-description h3 {
    margin-bottom: 0.5rem;
}
.search-results-description h3 a {
    color: #000;
}
.search-bar {
    background-color: #ffffff;
    padding:00rem;
    margin-bottom: 1rem;
}
.search-results .search-form-wrapper{
  padding-bottom: 1.5rem;
}
.search-bar-new {
  max-width: 100%;
  padding-right: 0px;
}
.search-btn {
  background-color: #D6A329;
  text-align: center;
  width: fit-content;
  display: flex;
  align-items: center;
}
.search-btn .btn {
  padding: 0rem 0rem;
  padding-left: 10px;
  padding-right: 10px;
}
.search-btn button {
  color: white;
}

.search-btn svg {
  font-size: 20px;
}
.search-bar-new input {
  border: none;
  font-size: 20px;
  font-family: 'MontserratRegular';
  padding-top: 5px;
  padding-bottom: 5px;
  letter-spacing: 3px;
}
.search-border{
  border-bottom:1px solid gray;
}
.search-fix{
  width: fit-content;
}
/* PROGRAM / SERVICE DETAILS PAGE */

.program-details {
  margin-top: 1rem;
    margin-bottom: 5rem;
}
.program-details .title {
    margin-bottom: 0.5rem;
}

.program-details .last-updated{
    font-size: 0.75rem;
}
.program-details .owner {
    display: block;
    font-size: 0.75rem;
    padding-top: 0.75rem;
}
.program-details .print-button{
    font-size: 1rem;
}
.program-details .category-list {
    font-size: 0.75rem;
    margin-top: 1rem;
    color: #448AFF;
}
.program-details .category-list a {
    font-weight: 400;
    color: #000000;
    text-decoration: underline;
}
.program-details .contents {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.program-details .contents h2 {
    margin-bottom: 1.5rem;
}
.program-details .contents ul {
    margin-top: 0rem;
}
.program-details .about {
    margin-top: 0rem;
    margin-bottom: 1rem;
}
.program-details .about ol li {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.program-details .who-qualifies {
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.program-details .how-do-i-apply {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.disabled-phone {
    font-weight: normal;
}
.geo-search {
  width: 70%;
  height: 50px;
  border-radius: 1.5rem;
  margin-bottom: 10px;
  margin-right: 5px;
  border-color: #448AFF;
  text-align: center;
}
.geo-button {
  margin-bottom: 10px;
  margin-left: 5px;
}
.map-header {
  width: 95%;
  height: 100%;
  margin-bottom: 30px;
}
.map-container {
  width: 90%;
  height: 65vh;
}
.tooltip {
  max-width: 45%;
}
/* Custom icons for list */
.program-details .how-do-i-apply ol {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 1.5rem 0;
    padding: 0;
}
.program-details .how-do-i-apply .how-do-i-apply-li {
    counter-increment: my-awesome-counter;
    display: flex;
    font-size: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}
.program-details .how-do-i-apply .how-do-i-apply-li::before {
    content: counter(my-awesome-counter);
    font-weight: bold;
    font-size: 3rem;
    margin-right: 0.5rem;
    font-family: 'PT Serif', serif;
    line-height: 1;
}
.program-details .how-do-i-apply .how-do-i-apply-li-content{
    padding-left: 0.5rem;
}
.program-details .how-do-i-apply ol li ol {
  list-style: decimal;
}
.program-details .how-do-i-apply ol li ul {
    margin-bottom: 2rem;
}
.program-details .how-do-i-apply ol li ul li {
    list-style: disc;
    max-width: 600px;
}
/*custom icons for list */
.program-details .what-do-i-need ul {
    list-style: none;
    counter-reset: my-awesome-counter;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}
.program-details .what-do-i-need .what-do-i-need-li {
    counter-increment: my-awesome-counter;
    display: flex;
    font-size: 1rem;
    margin-bottom: 1rem;
    width:100%;
}
.program-details .what-do-i-need .what-do-i-need-li::before {
    /* https://pictogrammers.github.io/@mdi/font/6.2.95/ */
    content: "\F05E1";
    font-weight: normal;
    font-size: 2rem;
    margin-right: 0.5rem;
    font-family: 'Material Design Icons';
    color: #448AFF;
    line-height: 1;

}
.program-details .what-do-i-need .what-do-i-need-li-content{
    padding-left: 0.5rem;
}
.program-details .what-do-i-need-li ul {
    display: block;
    margin-bottom: 2rem;
    list-style: disc;
}
.program-details .what-do-i-need-li ul li{
    margin: 0.5rem 0rem 0.5rem 1.5rem;
}
.program-details .what-do-i-need-faq ol li,
.program-details .what-do-i-need-faq ul li{
   margin: 1rem 0rem 1rem 1.5rem;
}
.documents, .summary-statement, .contact, .sidebar-block {
    padding: 1.5rem;
     -webkit-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.12);
    margin-top: 70px;
    margin-bottom: 1.5rem;
}
.documents h3, .summary-statement, .contact h3, .sidebar-block h3{
    margin-bottom: 1rem;
}
.related{
    margin-top: 2rem;
}
/* PROGRAM PAGE PRINT */
.print-page-header-container {
  margin-top: -135px;
  margin-bottom: 40px;
}
.print-page-header-logo {
  margin-top: 4px;
  max-width: 30px;
}
.print-page-header-text {
  font-size: 24px;
  letter-spacing: 2px;
  position: relative;
  top: 10px;
  left: 6px;
}
.program-image {
  width: 50%;
}
.print-page-last-updated {
  margin-top: 50px;
  padding: 30px;
  padding-bottom: 10px;
  border: 1px solid #000;
  font-size: 20px;
}
.print-page-last-updated p {
  margin-top: 16px;
}
.print-page-apply-section {
  width: 100%;
  margin-top: 60px;
}
.print-page-footer-titles {
  font-size: 32px;
}
.print-page-footer-211 {
  width: 100%;
}
.print-page-footer-211 img {
  margin-top: -10px;
  max-width: 50%;
}
.print-page-footer-need-help {
  border-radius: 20px;
  width: 100%;
}
.print-page-footer-need-help img {
  width: 2em;
  height: auto;
}
.need-help-divider {
  border-left: 1px solid #000;
}
.need-help-title-spacing {
  margin-top: 28px;
  padding-left: 12px;
}
.print-page-copyright img{
  margin-top: 60px;
  margin-bottom: 16px;
  width: 200px;
  height: auto;
}
/* 211 Chat */
.chat211-modal, .chat211-modal p, .chat211-modal h5 {
  font-family: roboto !important;
  color: #000 !important;
  overflow-y: auto;
}
.fade-bg {
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-dialog {
  max-width: 750px;
}
@media (max-width: 768px) {
  .modal-dialog {
    margin: 5%;
    width: auto;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .modal-dialog {
    width: 60%;
  }
}
.modal-header img {
  max-width: 84px;
}
.modal-header button:focus {
  outline: none;
}
.modal-close {
  font-size: 50px;
  opacity: 0.75;
  font-weight: 300;
}
.modal-footer i {
  font-size: 32px;
}
.modal-footer .decline-button {
  color: #004A98;
  border-color: #004A98;
  width: 150px;
  border-radius: 5px;
  font-weight: 600;
}
.modal-footer .chatnow-button {
  color: white;
  background-color: #004A98;
  min-width: 150px;
  border-radius: 5px;
  font-weight: 600;
}
.modal-footer .decline-button:hover, .modal-footer .chatnow-button:hover {
  background-color: #1565C0;
  border-color: #1565C0;
  color: white;
}


.chat-modal {
  max-width:600px;
  height: calc(100% - 250px);
  min-height: 350px;
  bottom: 20px;
  left: 15px;
  position: fixed;
  display: block;
  z-index: 1050;
  -webkit-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
}
@media (max-width: 768px) {
  .chat-modal {
    height: calc(100% - 100px);
    margin-bottom: 5%;
    max-width: 90%;
    bottom: 30px;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .chat-modal {
    max-width: 60%;
  }
}

/* CTA */
.cta {
    background-color: #448AFF;
    padding: 5rem 0 3rem 0;
    text-align: center;
    color: #fff;
}
.cta h2 {
    color: #fff;
    margin-bottom: 1rem;
}
.cta p {
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
    color: #ffffff;
}
.cta-btn-right .btn {
    position: relative;
    text-align: center;
}
/* FOOTER */
.footer {
    padding: 4rem 0rem 2rem 0rem;
    background-color: #eaedee;
}
.btn-back-to-top{
    display: none;
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 10000;
    color: #ffffff;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    -webkit-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
}
.footer .nb-logo {
    display: block;
    position: relative;
    text-align: center;
}
.footer .nb-logo img {
    max-width: 250px;
    position: relative;
    text-align: center;
}
.footer ul {
    margin: 0;
    padding: 0;
    display: block;
}
.footer-nav .nav-item {
    display: block;
    text-align: left;
}
.footer .footer-nav .nav-link {
  display: block;
  padding: 0.1rem 0rem;
  white-space: nowrap;
  width: min-content;
}
.footer .footer-nav .nav-link:hover {
  background: none;
  color: inherit;
}
.footer p {
    display: block;
    font-size: 0.75rem;
    text-align: left;
}
.feedback {
  font-size: 0.8rem;
  color: #000;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 10px;
  border-radius: 5px;
}

.banner-add-padding{
  padding-bottom: 20px;
}

.feedback-dev {
  color: #FFF !important;
}

/* 404 ERROR PAGE */
.not-found-image{
  display: block;
  position: relative;
  margin-top: 1rem;
  height: 100%;
  width: 60vw;
  max-width: 390px;
}
.not-found{
  margin-bottom: 2rem;
}
.not-found .col-md-6{
  padding: 0rem 1.75rem 2.25rem 1.75rem
}
.not-found h2{
  margin-bottom: 2rem;
}
.not-found .nb-logo{
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.not-found .nb-logo img{
  max-width: 250px;
  display: block;
}

/* ABOUT */
.about-banner {
  padding-bottom: 100px;
}
.about-banner .about-embed {
  width: 100%;
  height: 100%;
}
.about-banner .about-header {
  color: #33bcd2;
  font-family: 'PoppinsBold', Arial, Helvetica, sans-serif;
  font-size: xx-large;
}
.about-banner .about-description {
  color: #494949;
  font-family: 'PoppinsMedium', Arial, Helvetica, sans-serif;
}
.about-banner .about-button {
  font-family: 'PoppinsBold', Arial, Helvetica, sans-serif;
  background-color: #ff9864;
  border-color: #ff9864;
  color: white;
  font-size: large;
  vertical-align: middle;
  display: inline-block;
  border: 1px solid transparent;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}
.about-banner .about-button:hover {
  background-color: #ef7e4b;
  border-color: #ef7e4b;
  color: white;
}

.landing-footer .link-header {
  color: #0B3787;
  font-family: 'MontserratSemiBold';
  font-size: 16px;
  text-align: left;
}

.landing-footer .footer-nav .nav-link {
  font-family: 'MontserratMedium';
  display: block;
  padding: 0.1rem 0rem;
  font-size: 15px;
  text-decoration: none;
  color: #494949;
}

.landing-footer .footer-nav {
  padding-left: 0px;
  margin-bottom: -5px;
}
.landing-footer .nb-logo {
  display: block;
  float: left;
  width: 48px;
  height: 48px;
  padding-right: 10px;
}


.category-list {
  max-width: 900px;
}
.category-card {
  border-radius: 1rem;
  margin-bottom: 30px;
  color: white;
  max-width: 900px;
}

.category-card .category-row {
  margin-top: 15px;
  margin-bottom: 15px;
}

.category-card .card-title h3 {
  margin: unset;
  font-size: 30px;
  font-family: 'CormorantGaramondBold';
}

.category-card .icon-row .icon {
  color: white;
  font-size: 30px;
}

.category-card .body {
  text-align: center;
  position: relative;
  max-height: min-content;
}

.category-card .horizontal-bar {
  border-bottom: 3px solid white;
}

.category-card .card-text p {
  font-family: 'MontserratMedium';
  font-size: 14px;
  padding-right: 15px;
  padding-left: 15px;
  text-align: left;
}

.category-card img {
  width: 100%;
  height: 100%
}

.category-card .link-button {
  font-family: 'MontserratMedium';
  font-size: 14px;
  background-color: white;
  text-decoration: none;
}

.category-card a {
  text-decoration: none;
}

.category-card .img-first img{
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}
.category-card .img-second img{
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.category-learn-more {
  background-color: white;
  font-family: 'MontserratMedium';
  font-size: 14px;
  text-decoration: none;
  border-radius: 0.25rem;
  width: fit-content;
}

.category-learn-more:hover {
  text-decoration: underline;
}

/* MEDIA QUERIES */

/* EXTRA SMALL DEVICES */
/*(portrait phones, less than 576px) */
/* No media query since this is the default in Bootstrap*/
@media (min-width: 300px) {
  .landing-cta {
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    padding-bottom: 3rem;
    color: #fff;
  }
  .landing-cta .line-break {
    border-bottom: 1px solid white;
    width: 500px;
    margin-left: 65px;
    margin-right: 65px;
  }
  .landing-cta-info-container {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 65px;
    padding-left: 65px;
  }
  .landing-cta .landing-cta-text {
    font-family: 'Cormorant Garamond';
    font-weight: normal;
    padding-top: 45px;
  }
  .landing-footer .brand-header {
    padding-top: 15px;
  }
  .landing-footer .nb-logo {
    padding-right: 10px;
  }

  .landing-footer .link-header {
    text-align: center;
  }
  .landing-footer .footer-nav .nav-link {
    text-align: center;
  }
  .landing-footer .footer-nav {
    padding-right: 0px;
  }
  .collapsible-container {
    width: 470px;
  }
  .landing-cta-body {
    max-width: 470px;
  }
  .category-card .body {
    order: 1;
  }
  .category-card {
    max-width: 90%;
  }
  .icon-row {
    display: none;
  }
  iframe {
    width: 290px;
    height: 165.5px;
  }
}

@media (max-width: 543px){
  figcaption{
    justify-content: center;
    display: flex;
  }
}

/* CUSTOM FOR HEADER PADDING */
/*(landscape phones, 576px and up) */
@media (min-width: 480px) {
  body{
    padding-top: 0px;
  }
  .betaContainer {
    margin-top: 70px;
    font-size: 1rem;
    line-height: 1rem;
    background-color: #f2f2f2;
    padding: 7px;
    width: 100%;
    }
  .beta {
    float: left;
  }
  .betaButton {
    background-color: #00C853;
    color: #fff;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 10px;
    margin-top: 7px;
    border-radius: 5px;
  }
  .header-container {
    margin-bottom: 70px;
  }
  .collapsible-container {
    width: 470px;
  }
}

/* SMALL DEVICES */
/*(landscape phones, 576px and up) */
@media (min-width: 544px) {
  .xs-hide {
    display: inline-block;
  }
  .s-hide {
    display: none;
  }
  .navbar {
    padding: 0.75rem 0rem;
  }
  .btn-navbar {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .btn-menu{
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    font-weight: 400;
    border: 1px solid transparent;
    -webkit-border-radius: 1.5rem;
    -moz-border-radius: 1.5rem;
    border-radius: 1.5rem;
    line-height: 1.5;
    transition: 0.3s;
    font-size: 0.95rem;
    padding: .375rem 1rem .375rem .4rem;
    margin-left: 10px;
    text-align: center;
    background-color: #448AFF;
    border-color: #448AFF;
    color: #ffffff;
    cursor: pointer;
    transition: 0.3s;
  }
  .btn-menu i{
    position: relative;
    font-size: 1.5rem;
    padding: 0;
    margin: 0;
    line-height: 0;
    top: 4px;
  }
  .btn-search i{
    color: #448AFF;
    font-size: 1rem;
  }
  .btn-menu:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }
  .lang-lg-hide-en, .lang-lg-hide-fr{
    display: none;
  }
  .btn-lang-xs-show{
    display: inline-block;
  }
  .jumbotron {
    /* padding: 8rem 0rem 4rem 0rem; The settings to use when beta banner is disabled */
    padding: 2.5rem 0rem 4rem 0rem;
  }
  .header {
    padding: 2rem 0rem 1.5rem 0rem;
  }
  .custom-select{
    margin-top: 1rem;
  }
  .betaContainer {
    margin-top: 70px;
  }
  .primary-header{
    width: 540px;
  }
  iframe {
    width: 510px;
    height: 286.5px;
  }
}


/* MEDIUM DEVICES */
/*(Tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  h1 {
    font-size: 3.25rem;
    line-height: 3.5rem;
  }
  h2 {
    font-size: 2.222rem;
    line-height: 2.333rem;
    margin: 0rem 0rem 3rem 0rem;
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
    margin: 0rem 0rem 2rem 0rem;
  }
  h4 {
  }
  p {
    font-size: 1rem
  }
  .md-hide {
    display: inline-block;
  }
  .jumbotron {
    /* padding: 8rem 0rem 4rem 0rem; The settings to use when beta banner is disabled */
    padding: 2.5rem 0rem 4rem 0rem;
  }
  .search-form-input{
    height: 60px;
    line-height: 60px;
  }
  .search-form-pill .search-form-input{
    background-color: #ededed;
    height: 60px;
    line-height: 60px;
  }
  .search-form h3{
    height: 60px;
    line-height: 60px;
  }
  .search-form label{
    display: inline-block;
  }
  .search-form{
    margin-top: 1rem;
  }
  .desktop-hide {
    display: none;
  }
  .header h1 {
    font-size: 2.22rem;
    line-height: 2.33rem;
  }
  .header-select-menu-wrapper label {
    padding-top: 0.75rem
  }
  .footer .nb-logo {
    display: block;
    position: relative;
    text-align: left;
  }
  .footer .nb-logo img {
    max-width: 250px;
  }
  .btn-back-to-top{
    display: block;
  }
  .not-found-image{
      left: -2rem;
  }
  .betaButton {
    margin-top: 0px;
  }
  .primary-header{
    width: 720px;
  }
  .landing-cta .line-break {
    border-bottom: 1px solid white;
    width: 500px;
    margin-left: 65px;
    margin-right: 65px;
  }
  .landing-cta .landing-cta-text {
    font-family: 'Cormorant Garamond';
    font-weight: normal;
  }
  .navbar-toggle {
    display: none;
  }
  iframe {
    width: 680px;
    height: 408px;
  }
}


/* LARGE DEVICES */
/*(Desktops, 992px and up) */
@media (min-width: 992px) {
  .lg-show {
    display: inline-block;
  }
  .lg-hide{
    display: none;
  }
  nav.navbar.fixed-top {
    max-height: auto;
    overflow-y: visible;
  }
  .not-found-image{
    padding-top: 1.5rem;
  }
  .service-list-description {
    padding: 0rem 1.75rem 2.25rem 1.75rem;
  }
  .related-service-item  .service-list-description{
    padding: 2rem 1.75rem 2.25rem 1rem;
  }
  .primary-header{
    width: 960px;
  }
  .landing-cta .line-break {
    border-left: 1px solid white;
    border-bottom: unset;
    margin-left: unset;
    margin-right: unset;
    width: unset;
    height: 200px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .landing-cta .landing-cta-info-container {
    width: 100%;
  }
  .landing-cta .landing-cta-text {
    font-family: 'Cormorant Garamond';
    font-weight: normal;
    padding-top: 120px;
  }
  .landing-cta {
    padding: 5rem 0 3rem 0;
    text-align: center;
    color: #fff;
  }
  .landing-footer .link-header {
    text-align: left;
  }
  .landing-footer .footer-nav .nav-link {
    text-align: left;
  }
  .landing-cta-body {
    max-width: 940px;
  }
  .category-card .body {
    order: 0;
  }
  .category-card {
    max-width: 900px;
  }
  .icon-row {
    display: flex;
  }
  .category-card .img-first img{
    border-top-left-radius: 1rem;
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 0rem;
  }
  .category-card .img-second img{
    border-top-right-radius: 1rem;
    border-top-left-radius: 0rem;;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 0rem;;
  }
  .category-card-img{
    object-fit: cover;
  }
  iframe {
    width: 610px;
    height: 291px;
  }
  .landing-footer .footer-nav {
    columns: 2 auto;
  }
}

/* XLARGE DEVICES */
/*(Large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .primary-header {
    width: 1140px;
    text-align: start;
  }
  .collapsible-container {
    width: 940px;
  }
  iframe {
    width: 725.9px;
    height: 408px;
  }
}

/* XXLARGE DEVICES */
/*(Large desktops, 1440px and up) */
@media (min-width: 1440px) {
}

/* Smooth scroll links */
.scroll-link {
    cursor: pointer
}

.scroll-link-text {
    color: #448AFF;
}

.on-this-page{
  list-style: none;
  margin: 0;
  padding: 0;
}
.on-this-page a {
    display: block;
    text-decoration: none;
    padding: 0.4rem 1rem;
    color: #000000;
    border: 1px solid #eaeaea;
    margin-bottom: 5px;
    -webkit-box-shadow: 0 3px 30px -6px rgba(0,0,0,0.12);
    -moz-box-shadow: 0 3px 30px -6px rgba(0,0,0,0.12);
    box-shadow: 0 3px 30px -6px rgba(0,0,0,0.12);
    cursor: pointer;
}
.on-this-page a i{
  font-size: 1.15rem;
}
.on-this-page a:hover {
    text-decoration: none;
    -webkit-box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
    -moz-box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
    box-shadow: 0 8px 20px -6px rgba(0,0,0,0);
    text-decoration: underline !important;
}

/* loader */
/* Center the loader */
#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

#landing-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #33bcd2;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* Add animation to "page content" */
  .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
  }

  @-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 }
    to { bottom:0px; opacity:1 }
  }

  @keyframes animatebottom {
    from{ bottom:-100px; opacity:0 }
    to{ bottom:0; opacity:1 }
  }

  #myDiv {
    display: none;
    text-align: center;
  }


  .overlay {
    opacity: 0.5; /* Safari, Opera */
    -moz-opacity:0.50; /* FireFox */
    filter: alpha(opacity=50); /* IE */
    transition: opacity 0.2s linear;
    background-color: rgba(0,0,0,0.75);
    z-index: 1;
    height: 100%;
    width: 100%;
    position:fixed;
    top: 0px;
    left: 0px;
}

/* #dynamicAccordion .card{
  margin: 5px;
  padding: 2px;
} */

#dynamicAccordion .card-body{
  padding: 16px;
}

#dynamicAccordion .card {
  margin-bottom: 4px;
  overflow: hidden;
  border-style: none;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

#dynamicAccordion .card-header p{
  padding: 0px;
  margin: -10px -25px;
}



/* CALCULATOR*/
.overview-title{
  text-decoration: underline;
  margin-bottom: 15px;
}
.checkmark {
  padding-left:1.5rem;
}
.checkmark li {
  list-style-type:none;
  padding-left:1rem;
}
.checkmark li:before {
content: "\2713";
margin:0 10px 0 -28px;
}
.for-me-reqm-desc{
  margin-bottom: 0;
}
#calculator{
  margin: 36px auto 16px;
  background-color: #F2F2F2;
  padding-bottom: 32px;
}
hr{
  border-top: 1px dashed grey;
}
#calculator-contents .centered{
  text-align: center;
}
#calculator-contents .accordion {
  width: 100%;
}
#calculator-contents .card-header {
  padding: 0px;
}
#calculator-contents .card-header h2 {
  font-size: 40px;
}
#calculator-contents .card-body {
  padding-top: 25px;
  padding-bottom: 0px;
}
#calculator-contents .card {
  margin-bottom: 4px;
}
#calculator-contents .accordion {
  margin-bottom: 20px;
}
#calculator-contents .income-error {
  margin-top: 40px;
}
#calculator-contents .mb-0 strong {
  font-size: 20px;
}
#calculator-contents .form-row {
  margin-bottom: 7px;
}
#calculator-contents h3 {
  margin-bottom: 7px;
  margin-top: 30px;
}
#calculator-contents table, thead, tbody, td, tr, th {
  border-color: #fff;
  border-style: none;
}
#calculator-contents table, td, thead, th {
  margin: 0px;
  padding: 20px;
  vertical-align: top;
}
table{
  border-collapse: collapse;
}
table, th, td {
  border: 1px solid black;
}
#calculator-contents .error {
  color: red;
}
#calculator-contents .underlined{
  text-decoration: underline;
}
#calculator-contents table td + td {
  border-left:2px solid black;
}
#calculator-contents table th {
  border-bottom:2px solid black;
}
#calculator-contents table th + th {
  border-left:2px solid black
}
#calculator-contents table th {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

#calculator-contents input[type=number]::-webkit-inner-spin-button,
#calculator-contents input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.summary-statement{
  margin-bottom: 16px;
}

.summary-statement a{
  text-decoration: none !important;
}

.num-dep-input input ::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.num-dep-input input input[type=number] {
  -moz-appearance: textfield;
}
#calculator-contents .how-much-support-needed{
  margin-bottom: 36px;
}

#calculator-contents .family-type-input, .num-dependents-input{
  background-color: #fff;
  border: 2px solid #000;

}

#calculator-contents .reset-btn, .calculate-btn{
  margin-bottom: 10px;
}

#calculator-details .calculator-warning-centered{
  margin: 0 auto;
  text-align: center;
  margin-bottom: 16px !important;
}

#calculator-details .calculator-warning-centered p{
  margin-bottom: 0 !important;
}

#results-container .calculator-results-or{
  text-align: center;
  margin-bottom: 4px;
}

.calculator-output-amount{
  text-align: center;
  margin-bottom: 0;
}

#calculator-contents .calculator-output-table{
  margin-bottom: 24px;
}

.calculator-support-types-redirect{
  color: #0000EE;
  text-decoration: underline;
}

/* REGISTRATION FORM*/
.registration-navigation-btn{
  color: #fff;
}

.registration-form{
  margin: 36px auto 16px;
  background-color: #F2F2F2;
  padding: 32px 0;
}

.registration-form .registration-form-subtitle{
  margin-bottom: 0;
}

.registration-form .form-row{
  margin-bottom: 8px;
}

.registration-form .form-group{
  margin: 12px 0;
}

.registration-form .registration-submit{
  margin-top: 32px !important;
  padding: 8px 64px;
  font-size: 1.5rem !important;
  margin: 0 auto;
  display: block;
}

select.form-control.is-invalid{
  background-image: none;
}

.registration-submit-msg {
  margin-top: 32px;
  text-align: center;
}

.chb-application {
  margin-left: -32px;
  margin-right: -32px;
  background-color: #FFFFFF;
}

.chb-application .col-12 .household-section,
.rental-section, .income-section, .other-income-section,
.additional-info-section,
.consent-section, .declaration-section,
.final-review-section {
  background: #F2F2F2;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  padding: 32px;
  box-shadow: 2px 2px 8px #B0B0B0;
}

.show-modal {
  display: block;
}

.email-failure-title {
  color: red;
  margin-bottom: 32px;
}

.email-success-title {
  color: green;
  margin-bottom: 32px;
}

.modal-body {
  text-align: center;
  padding: 96px 18px;
}

.success-outline{
  border:green 1px solid;
}

.failure-outline{
  border:red 1px solid;
}
/* LANDING PAGE */
/* FONTS */
.landing-page .h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 2.1rem;
}
.landing-page .h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.944rem;
  line-height: 2rem;
}
/* CARDS */
.landing-page .card {
  margin-bottom: 0;
}
.landing-page .card-img-top {
  flex-shrink: 0;  /*Fixes the responsive image height issue in IE */
  object-fit: cover;
  width: 450px;
  height: 283px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.landing-page .card-title {
  background-color: #0a7c9b;
  font-family: 'PoppinsMedium', Arial, Helvetica, sans-serif;
  font-size: large;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.landing-page .card-header {
  background-color: white;
  padding-bottom: 0;
  border: none;
}
.landing-page .card-title .link {
  font-family: 'PoppinsBold', Arial, Helvetica, sans-serif;
  font-size: large;
  color: white;
  text-decoration: none;
}
.landing-page .card-body {
  font-family: 'PoppinsMedium', Arial, Helvetica, sans-serif;
  color: #494949;
  border-bottom: 2px #ededed solid;
  /* padding-top: 25px; */
  padding: 1.25rem 1.25rem 1.5rem 1.25rem;
}
.landing-page .card-body a {
  font-family: 'PoppinsBold', Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #494949;
  margin-top: 50px;
}
.landing-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  background: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../images/landingPageImage.jpeg');
  background-size: cover !important;
  background-position: 50% 45%;
  overflow: hidden;
}

.landing-title-1 {
  font-family: 'DMSerifRegular';
  font-size: 68px;
  line-height: 72px;
  color:rgb(237, 237, 237);
  text-shadow:  -1px -1px 0 rgba(0, 0, 0, 0.4),
                1px -1px 0 rgba(0, 0, 0, 0.4),
                -1px 1px 0 rgba(0, 0, 0, 0.4),
                1px 1px 0 rgba(0, 0, 0, 0.4);
}
.landing-title-2 {
  font-family: 'PublicSansMedium';
  font-size: 30px;
  line-height: 34px;
  color:rgb(237, 237, 237);
  text-shadow:  -1px -1px 0 rgba(0, 0, 0, 0.4),
                1px -1px 0 rgba(0, 0, 0, 0.4),
                -1px 1px 0 rgba(0, 0, 0, 0.4),
                1px 1px 0 rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 575px) {
  .landing-image-container {
    /* height: 500px; */
    justify-content: center;
    align-items: end;
    background-position-x: 80%;
    background-position-y: bottom;
    padding-bottom: 30px;
  }

  .landing-title-1 {
    font-size: 56px;
    line-height: 58px;
  }
  .landing-title-2 {
    font-size: 24px;
    line-height: 26px;
  }

}

.collapsible-text {
  font-size: 14px;
  font-family: 'MontserratMedium';
  justify-content: space-between;
}
.collapsible-item {
  background-color: #ededed;
  height: 33.33%;
  border-top: 2px solid white;
  font-family: 'MontserratMedium'
}
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.top-picks-icon {
  font-size: 36px;
}
.card .btn {
  float: none;
}
.no-style {
  text-decoration: none;
}
/* HEADER */
/* LANDING PAGE HEADER NAVBAR */
_:-ms-fullscreen, :root .navbar-default .container {
  display: block;
}
.navbar-default .btn-close i {
  color: black;
}
.navbar-toggle {
  border: none;
  background: transparent !important;
  width: 35px;
  height: 35px;
}
.navbar-default .inactiveLink {
  pointer-events: none;
  cursor: default;
}
.navbar-default .nav-item {
  margin-top: 0;
  width: min-content;
}
.navbar-default .btn-lang-xs-show {
  display: inline-block;
  font-family: 'PoppinsMedium', Arial, Helvetica, sans-serif;
  font-weight: lighter;
}
.navbar-default .nav-link {
  color: black;
  font-family: 'MontserratMedium';
  font-size: 12px;
  font-weight: lighter;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-block;
  padding-right: 0;
  padding-left: 0;
}
.navbar-default #report-abuse-button a {
  background-color: #651624;
  color: white !important;
  word-wrap: break-word;
  width: 135px;
  white-space: unset;
  border: none;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.navbar-default .services-tab a {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  color: #4e4e4e;
  text-decoration: none;
}
.navbar-default .navbar-survey {
  height: min-content;
  width: fit-content;
  background-color: white;
}
.navbar-default .navbar-survey:hover {
  background-color: #ededed;
}
.navbar-default .navbar-survey .form-label {
  color: #848484;
  font-family: 'PoppinsMedium', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: medium;
  white-space: nowrap;
}
.navbar-default .navbar-brand img {
    display: inline-block;
    width: 35px;
    height: 35px;
    vertical-align: top;
}
.navbar-default .navbar-icon {
  display: inline-block;
  height: 30px;
  width: 40px;
  padding-right: 8px;
}
/* FOOTER */
li.nav-item-fix {
  width: auto;
}
.landing-footer {
  padding-bottom: 0 !important;
  color: #33bcd2;
  background-color: #f3f6f9;
}
.landing-footer > .backToTopContainer > .btn-back-to-top {
  font-family: 'PoppinsSemiBold', Arial, Helvetica, sans-serif;
  background-color: #33bcd2;
  border-color: #33bcd2;
  color: #ffffff;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 10000;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  -webkit-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
}
.landing-footer > .backToTopContainer > .btn-back-to-top:hover {
  background-color: #31a6af;
  border-color: #31a6af;
}

.chat-bubble {
  position: fixed;
  bottom: 45px;
  right: 15px;
  font-size: 15px;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  border-style: solid;
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.2);
  box-shadow: 3px 3px 5px 0px rgba(0,0,0,0.2);
}
.btn-chat {
  position: fixed;
  bottom: 0px;
  left: 20px;
  padding: 10px 18px 8px;
  z-index: 99;
  color: white;
  border-color: white;
  border-width: 1px 1px 0 1px;
  font-size: 15px;
  font-family: 'PoppinsMedium', Arial, Helvetica, sans-serif;
  border-radius: 5px 5px 0 0;
  -webkit-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.2);
}
.btn-get-help {
  background-color: #004A98;
}
.btn-get-help:hover{
  color: white;
  background-color: #1565C0;
}
.btn-open-hide-chat {
  background-color: #DA291C;
}
.btn-open-hide-chat:hover {
  color: white;
  background-color: #AA1F2E;
}

.landing-footer .brand-header {
  color: #010000;
  white-space: nowrap;
}
a.nav-link-fix {
  width: auto !important;
  white-space: normal !important;
}

.nav-link-fix:hover {
  background-color: inherit;
}

a.nav-link-fix:hover {
  color: #448AFF !important;
}

.landing-footer .copyright {
  background-color: #083686;
  color: white;
  font-family: 'PoppinsMedium', Arial, Helvetica, sans-serif;
  font-size: large;
  text-align: left;
}
.landing-footer .nb-logo img {
  max-width: 250px;
  position: relative;
  text-align: center;
}
.landing-footer .footer-nav .nav-item {
  display: block;
  text-align: left;
}
.landing-footer {
  color: #494949;
  text-decoration: none;
  font-family: 'PoppinsSemiBold', Arial, Helvetica, sans-serif;
  font-size: large;
  display: block;
  padding: 0.1rem 0rem;
}
.landing-footer a {
  color: #494949;
  text-decoration: none;
  font-family: 'PoppinsMedium', Arial, Helvetica, sans-serif;
  font-size: large;
  display: block;
  padding: 0.1rem 0rem;
}
.landing-footer p {
  color: #494949;
  display: block;
  font-family: 'MontserratMedium';
  font-size: 16px;
  text-align: left;
  padding: 0.1rem 0rem;
}
/* CTA */
.landing-cta h1 {
  font-family: 'PoppinsBold', Arial, Helvetica, sans-serif;
}
.landing-cta h2 {
  font-family: 'PoppinsSemiBold', Arial, Helvetica, sans-serif;
  font-size: 25px;
}

.landing-cta .landing-cta-body {
  background-color: #0d3c35;
  border-radius: 15px;
}

.landing-cta .telephone-number {
  white-space: nowrap;
}

/* FONTS */
@font-face {
  font-family: 'PoppinsBold';
  src: url('../fonts/Poppins-Bold.woff2') format('woff2'),
       url('../fonts/Poppins-Bold.woff') format('woff'),
       url('../fonts/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'PoppinsMedium';
  src: url('../fonts/Poppins-Medium.woff2') format('woff2'),
       url('../fonts/Poppins-Medium.woff') format('woff'),
       url('../fonts/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('../fonts/Poppins-SemiBold.woff2') format('woff2'),
       url('../fonts/Poppins-SemiBold.woff') format('woff'),
       url('../fonts/Poppins-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'FontAwesomeProLightIcons';
  src: url('../fonts/Font-Awesome-5-Pro-Light-300.woff2') format('woff2'),
       url('../fonts/Font-Awesome-5-Pro-Light-300.woff') format('woff'),
       url('../fonts/Font-Awesome-5-Pro-Light-300.otf') format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'FontAwesomeProSolidIcons';
  src: url('../fonts/Font-Awesome-5-Pro-Solid-900.woff2') format('woff2'),
       url('../fonts/Font-Awesome-5-Pro-Solid-900.woff') format('woff'),
       url('../fonts/Font-Awesome-5-Pro-Solid-900.otf') format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: 'MontserratMedium';
  src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'MontserratSemiBold';
  src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'CormorantGaramondBold';
  src: url('../fonts/CormorantGaramond-Bold.ttf') format('truetype');
}
@font-face{
  font-family: 'MontserratBold';
  src: url('../fonts/Montserrat-Bold.ttf')format('truetype');
}
@font-face{
  font-family: 'MontserratRegular';
  src: url('../fonts/Montserrat-Regular.ttf')format('truetype');
}
@font-face{
  font-family: 'DMSerifRegular';
  src: url('../fonts/DMSerifText-Regular.ttf')format('truetype');
}
@font-face{
  font-family: 'PublicSansMedium';
  src: url('../fonts/PublicSans-Medium.ttf')format('truetype');
}
/* Font icons */
.fas {
  font-family: 'FontAwesomeProSolidIcons';
  font-style: normal !important;
}
.fal {
  font-family: 'FontAwesomeProLightIcons';
  font-style: normal !important;
}
.custom-white {
  color: white;
}
.custom-red {
  color: #ea7171;
}
.custom-purple {
  color: #a696ed;
}
.custom-yellow {
  color: #efb45e;
}
.custom-blue {
  color: #41aed8;
}
.custom-pink {
  color: #ea7faf;
}
.custom-green {
  color: #94c15b;
}
.custom-orange {
  color: #e89064;
}
.custom-light-blue {
  color: #70dde5;
}

.dev-version p {
  color: rgba(0, 0, 0, 0.4);
}

.screening-page a{
  word-break: break-all;
}
.jumbotron .col-12, .jumbotron {
  margin: 0px !important;
  padding: 0px !important;
}

@media(min-width: 576px) {
  .borderRightDesktop{
    border-right: 1px solid black;
  }
  /* .borderBottomMobile{
    border-bottom: "1px solid black"
  } */
}
@media(max-width: 575px) {
  .borderBottomMobile{
    border-bottom: 1px solid black
  }
}

/* Disable the weird margin setting */
.screening-page h3{
  margin-bottom: 0.5rem;
}

.screening-page h2{
  margin-bottom: 1rem;
}
.chb-form h3{
  margin-bottom: 0.5rem;
}

.chb-form h2{
  margin-bottom: 1rem;
}


.screening-proceed{
  margin-top: 32px !important;
  padding: 8px 32px;
  font-size: 15px !important;
  margin: 0 auto;
  display: block;
}


@media(min-width: 768px) {
  .halfWidthOnMd{
    width:50%
  }
}


.center-vertically {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

/* Mockup width requirement */
.homepage-panel {
  max-width: 990;
}

.homepage-panel-image {
  display: inline-block;
  overflow: hidden;
  width: 470px;
}

.homepage-panel-text {
  color: white;
  width: 470px;
}

.homepage-panel-button {
  display: flex;
  justify-content: left;
}

.homepage-panel-text h2 {
  margin-bottom: 1rem;
  margin-top: 0;
  padding-top: 0.5rem;
  font-size: 45px;
  font-family: "Cormorant Garamond";
  font-weight: bold;
  text-align: left;
}
.homepage-panel-text p {
  font-size: 15px;
  text-align: left;
  font-family: "MontserratMedium";
}

.homepage-panel-text a {
  font-family: "MontserratMedium";
  font-size: 15px;
  background-color: #ffffff;
  border-color: rgb(237, 237, 237);
  border-radius: 0.25rem;
}

.homepage-panel-text a:hover {
  text-decoration: underline !important;
}

.homepage-panel-image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .homepage-panel .order-1 {
    border-top-left-radius: 1rem !important;
    /* border-bottom-left-radius: 1rem !important; */
  }

  .homepage-panel .order-2 {
    border-top-right-radius: 1rem !important;
    /* border-bottom-right-radius: 1rem !important; */
  }
}
@media only screen and (max-width: 1199px) {
  .homepage-panel .homepage-panel-image {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    order: 1 !important;
  }

  .homepage-panel .homepage-panel-text {
    /* border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important; */
    order: 2 !important;
  }
}
.collapsible-item:hover h5 {
  text-decoration: underline !important;
}

.collapsible-item h5 {
  font-size: 15px;
}

.collapsible-item p {
  font-size: 14px;
  color: black;
  font-weight: normal;
}

/* Fix vertical alignment of collapsible arrow svg */
.card-header svg {
  margin-top: 0.175rem !important;
}

/* TODO: replace other uses of these colours with these classes */

.sdred-fg {
  color: #6f0d22 !important;
}
.sdred-bg {
  background-color: #6f0d22 !important;
}

.sdblue-fg {
  color: #00378c !important;
}
.sdblue-bg {
  background-color: #00378c !important;
}

.sdgreen-fg {
  color: #003e35 !important;
}
.sdgreen-bg {
  background-color: #003e35 !important;
}

.sdredlight-bg {
  background-color: rgba(111, 13, 34, 0.33) !important;
}
.sdbluelight-bg {
  background-color: rgba(0, 56, 140, 0.33) !important;
}
.sdgreenlight-bg {
  background-color: rgba(0, 62, 53, 0.33) !important;
}

/* Allow scrolling on navbar */
.navbar-collapse{
  max-height: 85vh;
  overflow-y: scroll !important;
}

.landing-subpage-title{
  font-family: 'Cormorant Garamond';
}

.tmpRemFix{
  font-size: 1.5rem !important;
}

li.screening-list-check {
  list-style: none;
}

li.screening-list-check:before {
  content: '✓';
  font-size: 20px;
  padding:10px;
}

li.chb-li-increase-padding {
  padding-left:15px;
}

.visually-hidden {
  display: none;
}

.align-left {
  display: flex;
  justify-content: left;
}

.align-right {
  display: flex;
  justify-content: right;
}

.align-center {
  display: flex;
  justify-content: center;
}

.caption {
  display: flex;
  flex-direction: column;
}

figcaption {
    font-size: 1rem;
    margin-top: 0;
    word-wrap: break-word;
}

.media--type-remote-video {
  display: flex;
  justify-content: center;
}

/* Print stuff that needs to be statically defined ahead of */
@page {
    /* Effectively mt-5 */
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
}

@media print {
    html, body {
      font-size: 24px !important;
    }

    ul {
        margin-bottom: 20px !important;
    }

    .row {
        margin-top: 24px;
    }

    #dynamicAccordion .card {
        border: 2px solid rgba(0,0,0,.125);
    }
    .card-header {
        background-color: rgba(0, 0, 0, .03);
        border-bottom: 2px solid rgba(0,0,0,.125);
    }

    .content-pane img:not(.show-print-image) {
        display: none !important;
    }
    article {
        display: none !important;
    }
}